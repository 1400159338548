.nav-item:hover {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: scale(1.1); /* You can adjust the scale factor as needed */
}

.navbar-nav .nav-link {
    font-size: 18px; /* Adjust the font size as needed */
    font-weight: bold; /* Add this line to make the font thicker */
}
