.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.logo {
  width: 50px;
  height: 50px;
  margin: 0 10px;
}

/* .card-body {
  border-bottom-left-radius: 1.5%;
  border-bottom-right-radius: 1.5%;
  background: linear-gradient(to right, #1A5276, #4A235A);
} */

#profile-card .card-body {
  border-bottom-left-radius: 1.5%;
  border-bottom-right-radius: 1.5%;
  background: linear-gradient(to right, #1A5276, #4A235A);
}

#comment-list .list-group-item {
  background: linear-gradient(to right, #1A5276, #4A235A);
}
