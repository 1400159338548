html,
body,
.view {
    height: 100%;
}

#mobile-box {
    width: 360px;
}

.card {
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.card .view {
    -webkit-border-top-left-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-right-radius: 10px;
}

.card h5 a {
    color: #0d47a1;
}

.card h5 a:hover {
    color: #072f6b;
}

#pButton {
    float: left;
}

#timeline {
    width: 90%;
    height: 2px;
    margin-top: 20px;
    margin-left: 10px;
    float: left;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.3);
}

#pButton {
    margin-top: 12px;
    cursor: pointer;
}

#playhead {
    width: 8px;
    height: 8px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    margin-top: -3px;
    background: black;
    cursor: pointer;
}

/* Ensure the card-content does not break the card's border-radius */
.card-content {
    display: flex;
    align-items: center; /* Vertically center items */
    width: 100%; /* Make the card-content wider */
    margin: 0 auto; /* Center the card-content */
}

.card-content .bg-image {
    flex: 1; /* Adjust the space taken by the image as needed */
    margin-right: 10px; /* Add some spacing between the image and the iframe */
}

.card-content .card-body {
    flex: 1; /* Adjust the space taken by the iframe as needed */
}

/* Optional: ensure the card itself does not overflow */
.card {
    overflow: hidden;
}
