/* CommentListItem.css */
.comment-list-item {
    overflow-x: auto;
    white-space: nowrap;
}

.comment-list-item span {
    display: inline-block;
}

.comment-list-item::-webkit-scrollbar {
    width: 12px; /* width of the vertical scrollbar */
}

.comment-list-item::-webkit-scrollbar-track {
    background: rgba(241, 241, 241, 0); /* color of the track with 100% transparency */
}

.comment-list-item::-webkit-scrollbar-thumb {
    background-color: #888; /* color of the thumb */
    border-radius: 6px; /* roundness of the thumb */
}

.comment-list-item::-webkit-scrollbar-thumb:hover {
    background: #555; /* color of the thumb on hover */
}